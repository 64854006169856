$.fn.raty.defaults.space = false;
$.fn.raty.defaults.hints = ['星1つ', '星2つ', '星3つ', '星4つ', '星5つ'];
$.fn.raty.defaults.starType = 'i';

$(function() {
  $('.star').raty({
    score() {
      return $('#review_star').val();
    },
    target() {
      const selector = $(this).data('target');
      const $target = $(selector)
      if (0 === $target.length) {
        return null;
      }
      return $target;
    },
    targetKeep: true,
    click(score, evt) {
      return $('#review_star').val(score);
    }
  });
  $('.star-readonly').raty({
    score() {
      return $(this).data('rating');
    },
    target() {
      const selector = $(this).data('target');
      const $target = $(selector)
      if (0 === $target.length) {
        return null;
      }
      return $target;
    },
    targetKeep: true,
    half:  true,
    readOnly: true
  });
});
