$(function() {
  const postal_code_id = '#address_postal_code';
  const todoufuken_code_id = '#address_todoufuken_code';
  const address1_id = '#address_address1';
  const address2_id = '#address_address2';

  const fill_address = (todoufuken_code, address1, address2) => {
    $(todoufuken_code_id).val(todoufuken_code);
    $(address1_id).val(address1);
    $(address2_id).val(address2);
  };

  $(document).on('click', '[data-id="fillCustomerAddress"]', e => {
    const $list = $('.fill-address .dropdown-address-list');
    if ($list.hasClass('show')) {
      $list.removeClass('show');
      return false;
    }
    const postal_code = $(postal_code_id).val()
    const url = `/postal_codes/zip/${postal_code}`;
    $.getJSON(url, null, function(data, status) {
      if (data.length === 1) {
        const { todoufuken_code, region, town, build } = data[0];
        fill_address(todoufuken_code, `${region || ''}${town || ''}`, (build || ''));
      } else {
        const $template = $list.find('.address-box.template');
        $list.find('.address-box:not(.template)').remove();
        data.forEach((address, index) => {
          const { todoufuken, region, town, build } = address;
          let $node = $template.clone(false).removeClass('template')
          const address1 = `${region || ''}${town || ''}`;
          const address2 = (build || '');
          $node.attr('data-todoufuken', todoufuken.code);
          $node.attr('data-address1', address1);
          $node.attr('data-address2', address2);
          $node.find('.todoufuken').text(todoufuken.name);
          $node.find('.address1').text(address1);
          $node.find('.address2').text(address2);
          $node.appendTo($list);
        });
        $list.addClass('show');
      }
    });
    return false;
  });

  $(document).on('click', '.dropdown-address-list .address-box:not(.template)', function(e) {
    fill_address($(this).data('todoufuken'), $(this).data('address1'), $(this).data('address2'));
  });

  $(document).on('click', '*:not([data-id="fillCustomerAddress"])', function(e) {
    const $list = $('.fill-address .dropdown-address-list');
    $list.removeClass('show');
  });
});
