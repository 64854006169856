import magnificPopupOptions from './magnific_popup_options';

$(function() {
  // documentにイベントハンドラを設定するために最初のページロード時のみ実行する
  $(document).on('ajax:success', '[data-ajax-handler="addWishlistContent"]', function(e) {
    // お気に入りに追加
    const [response, _status, _xhr] = e.detail;

    const $addWishlistButton      = $('[data-id="addWishlistButton"]');
    const $addWishlistButtonFaved = $('[data-id="addWishlistButtonFaved"]');
    $addWishlistButton.addClass('hidden');
    $addWishlistButton.attr('aria-hidden', true);
    $addWishlistButtonFaved.removeClass('hidden');
    $addWishlistButtonFaved.attr('aria-hidden', false);

    // ダイアログを開く
    $.magnificPopup.open(magnificPopupOptions({
      items: {
        src: response.partial,
        type: 'inline',
      },
    }));
    return false;
  }).on('ajax:success', '[data-ajax-handler="removeWishlistContent"]', function(e) {
    // お気に入りから削除
    const [response, _status, _xhr] = e.detail;
    const $target = $(e.target);
    $target.closest('.wishlist-content').fadeOut('normal', function() {
      $(this).html(response.partial).hide().fadeIn();
    });
    return false;
  });
});
