$(function() {
  const clearError = function() {
    $('input.error').removeClass('error');
    $('ul.js-error-messages').children().remove();
  };

  const showError = function($elm, msg) {
    $elm.addClass('error');
    $elm.siblings('ul.js-error-messages:first').append(`<li>${msg}</li>`);
  };

  const validateCreditCardInputs = function($cardNumber, $cardName, $cardExpireM, $cardExpireY, $securityCode) {
    let valid = true;
    const cardno = $cardNumber.val().trim();
    if (cardno === '') {
      showError($cardNumber, 'カード番号が入力されていません。');
      valid = false;
    } else if (!cardno.match(/^[0-9]{10,16}$/)) {
      showError($cardNumber, '無効なカードです。');
      valid = false;
    }

    const holdername = $cardName.val().trim().replace(/\s+/g, ' ').replace(/[Ａ-Ｚａ-ｚ]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    });
    if (holdername === '') {
      showError($cardName, '名義が入力されていません。');
      valid = false;
    } else if (!holdername.match(/^[a-zA-Z ]+$/)) {
      showError($cardName, '名義は半角英字で入力してください。');
      valid = false;
    }

    const expireM = $cardExpireM.val().trim();
    if (expireM === '') {
      showError($cardExpireM, '有効期限/月が入力されていません。');
      valid = false;
    } else if (!expireM.match(/^[0-9]{1,2}$/) || (parseInt(expireM) < 1 || parseInt(expireM) > 12)) {
      showError($cardExpireM, '有効期限/月に不正な値が入力されています。');
      valid = false;
    }

    const expireY = $cardExpireY.val().trim();
    if (expireY === '') {
      showError($cardExpireY, '有効期限/年が入力されていません。');
      valid = false;
    } else if (!expireY.match(/^[0-9]{1,2}$/)) {
      showError($cardExpireY, '有効期限/年に不正な値が入力されています。');
      valid = false;
    }

    if (valid) {
      var now = new Date();
      var nowYear = now.getFullYear();
      var nowMonth = now.getMonth() + 1;
      var yyyy = 2000 + parseInt(expireY);
      var mm = parseInt(expireM);
      if (nowYear > yyyy) {
        showError($cardExpireY, 'カードの有効期限/年が過ぎています。');
        valid = false;
      } else if (nowYear === yyyy && nowMonth > mm) {
        showError($cardExpireM, 'カードの有効期限/月が過ぎています。');
        valid = false;
      }
    }

    const securitycode = $securityCode.val().trim();
    if (securitycode === '') {
      showError($securityCode, 'セキュリティーコードが入力されていません。');
      valid = false;
    } else if (!securitycode.match(/^[0-9]{3,4}$/)) {
      showError($securityCode, 'セキュリティーコードに不正な値が入力されています。');
      valid = false;
    }

    if (!valid) {
      return null;
    }

    return {
      cardno,
      expireY,
      expireM,
      securitycode,
      holdername
    };
  };

  const getSbToken = function() {
    $('[data-id="buttonSubmitPayment"]').prop('disabled', true)

    if (typeof(com_sbps_system) === "undefined") {
      alert('SBトークン決済サービスが利用できません。');
      $('[data-id="buttonSubmitPayment"]').prop('disabled', false)
      return false;
    }

    const $sbCreditCardFormBlock = $('[data-id="SbCreditCardFormBlock"]');
    const merchantId = '' + $sbCreditCardFormBlock.data('sb-merchant-id');
    const serviceId = '' + $sbCreditCardFormBlock.data('sb-service-id');
    if (!merchantId || !serviceId) {
      alert('SBトークン決済サービスが利用できません。');
      $('[data-id="buttonSubmitPayment"]').prop('disabled', false)
      return false;
    }

    clearError();
    const $cardNumber = $sbCreditCardFormBlock.find('[data-id="cardNumber"]');
    const $cardName = $sbCreditCardFormBlock.find('[data-id="cardName"]');
    const $cardExpireM = $sbCreditCardFormBlock.find('[data-id="cardExpireM"]');
    const $cardExpireY = $sbCreditCardFormBlock.find('[data-id="cardExpireY"]');
    const $securityCode = $sbCreditCardFormBlock.find('[data-id="securityCode"]');
    const values = validateCreditCardInputs($cardNumber, $cardName, $cardExpireM, $cardExpireY, $securityCode);
    if (!values) {
      $('[data-id="buttonSubmitPayment"]').prop('disabled', false)
      return false;
    }
    console.log(values);
    const data = {
      merchantId, serviceId,
      ccNumber : values.cardno,
      ccExpiration : '20' + values.expireY + values.expireM,
      securityCode : values.securitycode
    };
    console.log(data);
    // トークン生成ロジック呼び出し
    com_sbps_system.generateToken(data, function(response) {
      console.log(response);
      if (response.result == "OK") {
        $sbCreditCardFormBlock.find('[data-id="token"]').val(response.tokenResponse.token);
        $sbCreditCardFormBlock.find('[data-id="tokenKey"]').val(response.tokenResponse.tokenKey);
        $sbCreditCardFormBlock.find('[data-id="cardBrandCode"]').val(response.tokenResponse.cardBrandCode);
        $sbCreditCardFormBlock.find('[data-id="maskedCcNumber"]').val(response.tokenResponse.maskedCcNumber);
        $cardNumber.val(response.tokenResponse.maskedCcNumber);
        $cardName.val('');
        $securityCode.val('');
        $('[data-id="orderPaymentForm"]').submit();
      } else {
        alert('トークン取得に失敗しました。');
        $('[data-id="buttonSubmitPayment"]').prop('disabled', false)
      }
    });
  };

  const getGmoToken = function() {
    $('[data-id="buttonSubmitPayment"]').prop('disabled', true)

    if (typeof(Multipayment) === "undefined") {
      alert('GMOトークン決済サービスが利用できません。');
      $('[data-id="buttonSubmitPayment"]').prop('disabled', false)
      return false;
    }

    const $GmoCreditCardFormBlock = $('[data-id="GmoCreditCardFormBlock"]');
    const gmoShopId = $GmoCreditCardFormBlock.data('gmo-shop-id');
    if (!gmoShopId) {
      alert('GMOトークン決済サービスが利用できません。');
      $('[data-id="buttonSubmitPayment"]').prop('disabled', false)
      return false;
    }

    Multipayment.init(gmoShopId);

    clearError();
    const $cardNumber = $GmoCreditCardFormBlock.find('[data-id="cardNumber"]');
    const $cardName = $GmoCreditCardFormBlock.find('[data-id="cardName"]');
    const $cardExpireM = $GmoCreditCardFormBlock.find('[data-id="cardExpireM"]');
    const $cardExpireY = $GmoCreditCardFormBlock.find('[data-id="cardExpireY"]');
    const $securityCode = $GmoCreditCardFormBlock.find('[data-id="securityCode"]');
    const values = validateCreditCardInputs($cardNumber, $cardName, $cardExpireM, $cardExpireY, $securityCode);
    if (!values) {
      $('[data-id="buttonSubmitPayment"]').prop('disabled', false)
      return false;
    }
    console.log(values);
    const data = {
      cardno: values.cardno,
      securitycode: values.securitycode,
      holdername: values.holdername,
      expire: ('00' + values.expireY).slice(-2) + ('00' + values.expireM).slice(-2)
    };
    console.log(data);
    Multipayment.getToken(data, function(result) {
      console.log(result);
      if (result.resultCode === '000') {
        $GmoCreditCardFormBlock.find('[data-id="maskedCardNo"]').val(result.tokenObject.maskedCardNo);
        $GmoCreditCardFormBlock.find('[data-id="token"]').val(result.tokenObject.token);
        $GmoCreditCardFormBlock.find('[data-id="toBeExpiredAt"]').val(result.tokenObject.toBeExpiredAt);
        //result.tokenObject.isSecurityCodeSet;
        $cardNumber.val('');
        $cardName.val('');
        $securityCode.val('');
        $('[data-id="orderPaymentForm"]').submit();
      } else {
        alert('GMOトークン決済サービスが利用できません。');
        $('[data-id="buttonSubmitPayment"]').prop('disabled', false)
      }
    });
  };

  function getSbCredit3d2Token() {
    $('[data-id="buttonSubmitPayment"]').prop('disabled', true)

    if (typeof(com_sbps_system_tds2) === "undefined") {
      alert('SBトークン決済サービスが利用できません。');
      $('[data-id="buttonSubmitPayment"]').prop('disabled', false)
      return false;
    }

    const $sbCredit3d2FormBlock = $('[data-id="SbCredit3d2FormBlock"]');
    const merchantId = '' + $sbCredit3d2FormBlock.data('sb-merchant-id');
    const serviceId = '' + $sbCredit3d2FormBlock.data('sb-service-id');
    if (!merchantId || !serviceId) {
      alert('SBトークン決済サービスが利用できません。');
      $('[data-id="buttonSubmitPayment"]').prop('disabled', false)
      return false;
    }

    clearError();
    const $billingLastName = $sbCredit3d2FormBlock.find('[data-id="billingLastName"]');
    const $billingFirstName = $sbCredit3d2FormBlock.find('[data-id="billingFirstName"]');
    const $billingPostalCode = $sbCredit3d2FormBlock.find('[data-id="billingPostalCode"]');
    const $billingCity = $sbCredit3d2FormBlock.find('[data-id="billingCity"]');
    const $billingAddress1 = $sbCredit3d2FormBlock.find('[data-id="billingAddress1"]');
    const $billingAddress2 = $sbCredit3d2FormBlock.find('[data-id="billingAddress2"]');
    const $billingAddress3 = $sbCredit3d2FormBlock.find('[data-id="billingAddress3"]');
    const $billingPhone = $sbCredit3d2FormBlock.find('[data-id="billingPhone"]');
    const $workPhone = $sbCredit3d2FormBlock.find('[data-id="workPhone"]');
    const $shippingPostalCode = $sbCredit3d2FormBlock.find('[data-id="shippingPostalCode"]');
    const $shippingCity = $sbCredit3d2FormBlock.find('[data-id="shippingCity"]');
    const $shippingAddress1 = $sbCredit3d2FormBlock.find('[data-id="shippingAddress1"]');
    const $shippingAddress2 = $sbCredit3d2FormBlock.find('[data-id="shippingAddress2"]');
    const $shippingAddress3 = $sbCredit3d2FormBlock.find('[data-id="shippingAddress3"]');
    const $email = $sbCredit3d2FormBlock.find('[data-id="email"]');
    const data = {
      merchantId, serviceId,
      billingLastName: $billingLastName.val(),
      billingFirstName: $billingFirstName.val(),
      billingPostalCode: $billingPostalCode.val(),
      billingCity: $billingCity.val(),
      billingAddress1: $billingAddress1.val(),
      billingAddress2: $billingAddress2.val(),
      billingAddress3: $billingAddress3.val(),
      workPhone: $workPhone.val(),
      shippingPostalCode: $shippingPostalCode.val(),
      shippingCity: $shippingCity.val(),
      shippingAddress1: $shippingAddress1.val(),
      shippingAddress2: $shippingAddress2.val(),
      shippingAddress3: $shippingAddress3.val(),
      email: $email.val()
    };
    console.log(data);
    // トークン生成ロジック呼び出し
    com_sbps_system_tds2.generateToken(data, function(response) {
      console.log(response);
      if (response.result == "OK") {
        $sbCredit3d2FormBlock.find('[data-id="token"]').val(response.tokenResponse.tds2infoToken);
        $sbCredit3d2FormBlock.find('[data-id="tokenKey"]').val(response.tokenResponse.tds2infoTokenKey);
        $('[data-id="orderPaymentForm"]').submit();
      } else {
        alert('トークン取得に失敗しました。');
        $('[data-id="buttonSubmitPayment"]').prop('disabled', false)
      }
    });
  };

  // 送信ボタン押下時にGMO/SBPSのワンタイムトークンを取得する
  $('[data-id="buttonSubmitPayment"]').on('click', function() {
    if ($('input[value="gmo_credit_card"]').prop('checked')) {
      const $gmoUsePreviousCard = $('[data-id="GmoCreditCardFormBlock"] [data-id="usePreviousCard"]')
      const checked = $gmoUsePreviousCard.prop('checked');
      if (!checked) {
        getGmoToken();
        return false;
      }
    }
    if ($('input[value="sb_credit_card"]').prop('checked')) {
      const $sbUsePreviousCard = $('[data-id="SbCreditCardFormBlock"] [data-id="usePreviousCard"]')
      const checked = $sbUsePreviousCard.prop('checked');
      if (!checked) {
        getSbToken();
        return false;
      }
    }
    if ($('input[value="sb_credit3d2"]').prop('checked')) {
      getSbCredit3d2Token();
      return false;
    }
  });

  // ポイントとクーポンのラジオボタンの制御
  const updateCouponIdRadios = function() {
    const allowPointAndCouponUseTogather = $('input[data-id="allowPointAndCouponUseTogather"]').val();
    if ('true' == allowPointAndCouponUseTogather) {
      return;
    }
    const checkUsePoint = $('input[data-id="checkUsePoint"]:checked').val();
    if ('true' == checkUsePoint) {
      $('input[data-id="checkCouponUnuse"]').prop('checked', true);
      $('input[data-id="checkCouponId"]').prop('disabled', true);
    } else {
      $('input[data-id="checkCouponId"').prop('disabled', false);
    }
  };
  $('input[data-id="checkUsePoint"]').on('change', updateCouponIdRadios);
  updateCouponIdRadios();

  // 領収書ラジオボタンの enable/disable
  const updateReceiptRadios = function() {
    const paymentTypeLabel = $('input[data-id="paymentTypeLabel"]:checked').val();
    switch(paymentTypeLabel)
    {
      case "np_connect_pro":
      case "np_convenience":
      case "np_common_atobarai":
      case "cod":
        $('input[data-id="needReceipt"][value="false"]').prop('checked', true).change();
        $('input[data-id="needReceipt"][value="true"]').prop('disabled', true);
        break;
      default:
        $('input[data-id="needReceipt"][value="true"]').prop('disabled', false);
        break;
    }
  };
  $('input[data-id="paymentTypeLabel"]').on('change', updateReceiptRadios);
  updateReceiptRadios();

  // SBPS: 前回使用したカード情報を使用するチェックボックスに応じてフォームをenable/disable
  const $sbUsePreviousCard = $('[data-id="SbCreditCardFormBlock"] [data-id="usePreviousCard"]')
  const updateSbCreditCardInputs = function() {
    const checked = $sbUsePreviousCard.prop('checked');

    const $sbCreditCardFormBlock = $('[data-id="SbCreditCardFormBlock"]');
    const $newCardBlock = $sbCreditCardFormBlock.find('[data-id="newCardBlock"]');
    if (checked) {
      $newCardBlock.addClass('disabled');
    } else {
      $newCardBlock.removeClass('disabled');
    }
    const dataIds = [
      'token',
      'tokenKey',
      'cardBrandCode',
      'maskedCcNumber',
      'cardNumber',
      'cardName',
      'cardExpireM',
      'cardExpireY',
      'securityCode',
    ];
    dataIds.forEach(dataId => {
      const $elm = $sbCreditCardFormBlock.find(`[data-id="${dataId}"]`);
      $elm.prop("disabled", checked)
    });
  }
  $sbUsePreviousCard.on('change', updateSbCreditCardInputs).trigger('change');

  // GMO: 前回使用したカード情報を使用するチェックボックスに応じてフォームをenable/disable
  const $gmoUsePreviousCard = $('[data-id="GmoCreditCardFormBlock"] [data-id="usePreviousCard"]')
  const updateGmoCreditCardInputs = function() {
    const checked = $gmoUsePreviousCard.prop('checked');

    const $gmoCreditCardFormBlock = $('[data-id="GmoCreditCardFormBlock"]');
    const $newCardBlock = $gmoCreditCardFormBlock.find('[data-id="newCardBlock"]');
    if (checked) {
      $newCardBlock.addClass('disabled');
    } else {
      $newCardBlock.removeClass('disabled');
    }
    const dataIds = [
      'token',
      'tokenKey',
      'cardBrandCode',
      'maskedCcNumber',
      'cardNumber',
      'cardName',
      'cardExpireM',
      'cardExpireY',
      'securityCode',
    ];
    dataIds.forEach(dataId => {
      const $elm = $gmoCreditCardFormBlock.find(`[data-id="${dataId}"]`);
      $elm.prop("disabled", checked)
    });
  }
  $gmoUsePreviousCard.on('change', updateGmoCreditCardInputs).trigger('change');
});
