$(function() {
  $('#slider').slick({
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024, // less than
        settings: {
          variableWidth: false,
          centerPadding: '100px',
        }
      },
      {
        breakpoint: 680,
        settings: {
          centerMode: false,
          variableWidth: false,
        }
      },
    ]
  });
});
