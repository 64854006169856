(function() {
  const setLabelColor = () => {
    $('.label[data-color]').each(function() {
      let color = tinycolor($(this).data('color'))
      if (color.isValid()) {
        if (color.isLight()) {
          $(this).css('color', '#222')
        } else {
          $(this).css('color', '#fff')
        }
        $(this).css('background-color', color.toString())
      }
    })
  }

  if (typeof(Turbolinks) != 'undefined') {
    $(document).on('turbolinks:load', setLabelColor)
  } else {
    $(document).ready(setLabelColor)
  }
})()
