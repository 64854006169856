import { numberWithDelimiter } from '../lib/utils';
import magnificPopupOptions from './magnific_popup_options';

$(function() {
  // 新しいお届け先を登録する/お届け先の情報を変更
  $(document).on('ajax:success', '[data-ajax-handler="popup"]', function(e) {
    const [response, _status, _xhr] = e.detail;
    // ダイアログを開く
    $.magnificPopup.open(magnificPopupOptions({
      items: {
        src: response.partial,
        type: 'inline',
      }
    }));
    return false;
  }).on('ajax:success', '[data-ajax-handler="addAddress"]', function(e) {
    const [response, _status, _xhr] = e.detail;
    const $list = (response.address_type == 'shipping_address') ?
          $('[data-id="shippingAddressBooks"]') : $('[data-id="billingAddressBooks"]');
    const $btn = $list.find('.add-button');
    if ($btn) {
      $btn.before(response.partial);
    } else {
      $list.append(response.partial);
    }
    $.magnificPopup.close();
    return false;
  }).on('ajax:success', '[data-ajax-handler="updateAddress"]', function(e) {
    const [response, _status, _xhr] = e.detail;
    $(`#${response.dom_id}`).replaceWith(response.partial);
    $.magnificPopup.close();
    return false;
  }).on('ajax:success', '[data-ajax-handler="deleteAddress"]', function(e) {
    const [response, _status, _xhr] = e.detail;
    $(`#${response.dom_id}`).fadeOut();
    $.magnificPopup.close();
    return false;
  }).on('ajax:success', '[data-ajax-handler="selectFromAddress"]', function(e) {
    const [response, _status, _xhr] = e.detail;
    $('[data-id="fromAddressBook"]').html(response.partial);
    $.magnificPopup.close();
    return false;
  }).on('ajax:success', '[data-ajax-handler="updateFromAddress"]', function(e) {
    const [response, _status, _xhr] = e.detail;
    // ダイアログを更新
    $.magnificPopup.open({
      items: {
        src: response.partial,
        type: 'inline',
      }
    });
    if (response.partial2) {
      $('[data-id="fromAddressBook"]').html(response.partial2);
    }
    return false;
  }).on('ajax:error', '[data-ajax-handler]', function(e) {
    const [response, _status, _xhr] = e.detail;
    if (response.partial) {
      // ダイアログを更新
      $.magnificPopup.open(magnificPopupOptions({
        items: {
          src: response.partial,
          type: 'inline',
        }
      }));
    } else {
      $.magnificPopup.close();
    }
    return false;
  });

  // ダイアログを閉じるボタン
  $(document).on('click', '[data-handler="closeDialog"]', function(e) {
    $.magnificPopup.close();
    const href = $(this).attr('href');
    if (href != '#' && href != '') {
      window.location.href = href;
    }
    return false;
  });

  // 住所選択状態の更新
  const updateShippingCustomerAddressSelect = () => {
    let disabled = true;
    $('[data-id="shippingAddressBooks"] .address-book').each(function() {
      const $this = $(this);
      if (0 === $this.find('input[type="checkbox"]:checked').length) {
        $this.removeClass('selected').attr('aria-selected', 'false');
      } else {
        $this.addClass('selected').attr('aria-selected', 'true');
        disabled = false;
      }
    });
    // 送信ボタンの有効無効
    $('[data-id="orderAddressForm"] input[type="submit"]').prop('disabled', disabled).attr('aria-disabled', disabled);
  };
  updateShippingCustomerAddressSelect();

  $('[data-id="shippingAddressBooks"]').on('click', 'input[type="checkbox"]', function(e) {
    updateShippingCustomerAddressSelect();
  });
});
