import { numberWithDelimiter } from '../lib/utils';

$(function() {
  const $orderQuantityForm = $('[data-id="orderQuantityForm"]');
  if ($orderQuantityForm.length === 0) return;

  const showBlock = $target => {
    $target.slideDown('fast', function () {
      $target.removeClass('hidden')
      $target.attr('aria-hidden', 'false');
    });
  }

  const hideBlock = $target => {
    $target.slideUp('fast', function () {
      $target.addClass('hidden')
      $target.attr('aria-hidden', 'true');
    });
  }

  const updateShippingQuantitiesSelect = () => {
    // 送付先の合計個数が0の場合
    let assignedQuantities = new Map();
    $orderQuantityForm.find('[data-selector="destination"]').each(function() {
      const $this = $(this);
      const $quantities = $this.find('select[data-selector="quantity"]');
      const total = $quantities.toArray().reduce((prev, curr) => (prev + parseInt($(curr).val())), 0);
      console.log(total);
      if (0 === total) {
        $this.addClass('unassigned');
        showBlock($this.find('.notice-unassigned-destination'));
      } else {
        $this.removeClass('unassigned');
        hideBlock($this.find('.notice-unassigned-destination'));
      }
      $quantities.each(function() {
        const itemDigest = $(this).data('item-digest'),
              quantity = parseInt($(this).val());
        assignedQuantities.set(itemDigest, (assignedQuantities.has(itemDigest) ? assignedQuantities.get(itemDigest) : 0) + quantity);
      });
    });

    const $noticeUnassignedAll = $('.notice-unassigned-all');
    const $noticeUnassignedItem = $('.notice-unassigned-item');
    const $noticeQuantityIncreased = $('.notice-quantity-increased');

    // どの届け先にも商品を送らない場合
    const totalQuantity = Array.from(assignedQuantities.values()).reduce((prev, curr) => (prev + curr), 0);
    if (0 === totalQuantity) {
      showBlock($noticeUnassignedAll);
      hideBlock($noticeUnassignedItem);
      hideBlock($noticeQuantityIncreased);
      $orderQuantityForm.find('input[type="submit"]').prop('disabled', true);
    } else {
      hideBlock($noticeUnassignedAll);
      $orderQuantityForm.find('input[type="submit"]').prop('disabled', false);

      // カート内の個数より少ない場合
      let decreaseNoticeShow = false;
      $noticeUnassignedItem.find('[data-selector="cartContent"]').each(function() {
        const $this = $(this),
              itemDigest = $this.data('item-digest'),
              quantity = parseInt($this.data('quantity'));
        const assignedQuantity = (assignedQuantities.has(itemDigest) ? assignedQuantities.get(itemDigest) : 0);
        if (assignedQuantity < quantity) {
          $this.find('.count').text(numberWithDelimiter(quantity - assignedQuantity));
          showBlock($this);
          decreaseNoticeShow = true;
        } else {
          hideBlock($this);
        }
      });
      if (decreaseNoticeShow) {
        showBlock($noticeUnassignedItem);
      } else {
        hideBlock($noticeUnassignedItem);
      }

      // カート内の個数より多い場合
      let increaseNoticeShow = false;
      $noticeQuantityIncreased.find('[data-selector="cartContent"]').each(function() {
        const $this = $(this),
              itemDigest = $this.data('item-digest'),
              quantity = parseInt($this.data('quantity'));
        const assignedQuantity = (assignedQuantities.has(itemDigest) ? assignedQuantities.get(itemDigest) : 0);
        if (assignedQuantity > quantity) {
          $this.find('.count').text(numberWithDelimiter(assignedQuantity - quantity));
          showBlock($this);
          increaseNoticeShow = true;
        } else {
          hideBlock($this);
        }
      });
      if (increaseNoticeShow) {
        showBlock($noticeQuantityIncreased)
      } else {
        hideBlock($noticeQuantityIncreased)
      }
    }
  };
  updateShippingQuantitiesSelect();

  $orderQuantityForm.on('change', 'select[data-selector="quantity"]', function(e) {
    updateShippingQuantitiesSelect();
  });

  $orderQuantityForm.find('.scroll-top').on('click', function() {
    $("html,body").animate({scrollTop: $orderQuantityForm.offset().top});
    return false;
  });
});
