$(function(e) {
  var $header = $('#top-head');

  // Nav Toggle Button
  $('#nav-toggle').click(function(){
    $header.toggleClass('open');
  });

  var position;
  // タップイベント
  $('body').bind("touchstart", function(event) {
    position = getPosition(event);
  });
  // スワイプイベント
  $('body').bind("touchmove", function(event) {
    if (Math.abs(position - getPosition(event)) > 10) {
      toggleClose();
    }
  });

  // 座標を取得
  function getPosition(event) {
    var pageY = 0;
    if (event.originalEvent.touches) {
      pageY = event.originalEvent.touches[0].pageY;
    } else {
      pageY = event.pageY;
    }
    return pageY;
  }

  // メニューを閉じる
  function toggleClose()
  {
    if ($header.hasClass('open')) {
      $header.removeClass('open');
    }
  }

  $('.notice a.close').on('click', function () {
    $(this).closest('.notice').slideUp();
  });
});
